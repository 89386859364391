import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useMvpAppDevelopmentStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      tailoredOne: file(
        relativePath: { eq: "company-photo/company-photo-34.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredTwo: file(
        relativePath: { eq: "company-photo/company-photo-37.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      clientImpact: file(
        relativePath: { eq: "avatars/clients/large-photo/client-impact.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      portfolioImpact: file(
        relativePath: { eq: "homepage/discover-works-portfolio-12.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      portfolioCaker: file(
        relativePath: { eq: "homepage/discover-works-portfolio-7.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useMvpAppDevelopmentStaticQuery
