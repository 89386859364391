import { WithOptional } from '~/types/helper'
import { ExpertiseSectionMock } from '~/types/mock'

const MvpAppDevTypes: WithOptional<ExpertiseSectionMock, 'icon'>[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Low-fidelity MVP',
    description:
      'Simplicity reigns supreme in this approach. Catch the magic of minimalism as we focus on the core functionalities that breathe life into your app idea. Embracing a lean and agile mindset, we craft a fundamental yet functional prototype that serves as the perfect springboard for iterative improvements.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'High-fidelity MVP',
    description:
      'It is about the high level of refinement and sophistication. Every pixel, every interaction, and every detail is meticulously crafted to create a stunning and immersive user experience. Step into the shoes of your end-users and witness their delight as they engage with a fully realized prototype that feels like a finished product.',
  },
]

export default MvpAppDevTypes
