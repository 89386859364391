import Title from '~/components/shared/Title/'
import { DataContentProps } from '~/types/helper'
import { ProcessMock } from '~/types/mock'
import { cx } from '~/utils'

import './DevelopmentProcess.scss'

interface DevelopmentProcessProps extends DataContentProps<ProcessMock> {
  dataImage: string
}

const DevelopmentProcess = ({
  dataContent,
  dataImage,
}: DevelopmentProcessProps) => (
  <div className="container container-md">
    <div className="row developmentProcessSection__row">
      {dataContent.map(({ num, link, title, description, classNames }) => (
        <div className="col-12 col-lg-4 col-md-6" key={num}>
          <div className={cx('developmentProcessSection__card', classNames)}>
            <div className="developmentProcessSection__num">
              <span>{num}</span>
            </div>
            <Title
              link={link}
              headingLevel="h3"
              className="developmentProcessSection__title"
            >
              {title}
            </Title>
            {description && (
              <div className="developmentProcessSection__description">
                {description}
              </div>
            )}
          </div>
        </div>
      ))}

      <div className="d-none d-lg-block developmentProcessSection__line">
        <img
          src={dataImage}
          alt="Our development process | Codica"
          title="Our development process"
          width="100%"
          height="100%"
          loading="lazy"
        />
      </div>
    </div>
  </div>
)

export default DevelopmentProcess
