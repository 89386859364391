import { ProcessMock } from '~/types/mock'

const MvpAppDevProcess: ProcessMock[] = [
  {
    num: '1',
    title: 'Product discovery',
    link: '/services/product-discovery/',
    description: (
      <>
        <ul key="ProductDiscoveryKey">
          <li>
            Collection of information about clients and competitors; strategic
            planning
          </li>
          <li>Discussing the main functions and ideas of the product</li>
          <li>Selection of technologies</li>
        </ul>
      </>
    ),
    classNames: 'cardOne',
  },
  {
    num: '2',
    title: 'UI/UX design services',
    link: '/services/ui-ux-design/',
    description: (
      <>
        <ul key="designKey">
          <li>
            Keeping up with the latest design trends to meet the brand’s
            requirements
          </li>
          <li>Quality interface (UI) development</li>
          <li>Improving the user experience (UX)</li>
        </ul>
      </>
    ),
    classNames: 'cardTwo',
  },
  {
    num: '3',
    title: 'MVP app development',
    description: (
      <>
        <ul key="mvpAppDevKey">
          <li>Writing the code for the project</li>
          <li>Develop system architecture</li>
          <li>Compliance with the latest industrial practices and standards</li>
        </ul>
      </>
    ),
    classNames: 'cardThree',
  },
  {
    num: '4',
    title: 'Quality assurance testing services',
    link: '/services/quality-assurance/',
    description: (
      <>
        <ul key="qaKey">
          <li>Testing and elimination of errors (bugs)</li>
          <li>Logic-based and user-flow testing</li>
          <li>Deployment of test versions on the server</li>
        </ul>
      </>
    ),
    classNames: 'cardFour',
  },
  {
    num: '5',
    title: 'Deployment monitoring',
    description: (
      <>
        <ul key="deploymentMonitoringKey">
          <li>Official launch of the project</li>
          <li>Product support and improvement</li>
          <li>Adding new features based on analytics and KPIs</li>
        </ul>
      </>
    ),
    classNames: 'cardFive',
  },
]

export default MvpAppDevProcess
