import { FAQ } from '~/types/data-array'

const MvpAppDevFAQ: FAQ[] = [
  {
    question: 'What is MVP in app development?',
    answer:
      'Minimum viable product (MVP) is a fundamental concept and approach used in software development, particularly in building new apps or products. The main idea behind MVP is to develop and release a basic version of the app with minimal features and functionalities, just enough to satisfy early users.',
  },
  {
    question: 'What are the top technologies to build app MVPs?',
    answer:
      'Building an app MVP requires choosing the right technologies to develop and deploy a functional product with minimal resources quickly. There is a top MVP app tech stack for Android (Kotlin, Java), iOS (Swift), cross-platform (React, JavaScript), databases (MySQL, PostgreSQL), and more.',
  },
  {
    question: 'How do we create an MVP application?',
    answer:
      'Creating an MVP app involves making a simplified version of your product. There are main stages, such as market research and validation, creating wireframes and mockups, selecting the right technology stack, developing the MVP, testing and quality assurance, deployment, and iterating and improving.',
  },
]

export default MvpAppDevFAQ
