import { DataTabList } from '~/types/data-array'

const MvpAppDevTabList: DataTabList[] = [
  {
    name: 'Fitness app',
  },
  {
    name: 'Bakery platform',
  },
]

export default MvpAppDevTabList
