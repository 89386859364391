import { StaticImage, getImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { WorksTabsContent } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'
import useMvpAppDevelopmentStaticQuery from '~/views/Services/MvpAppDevelopment/useMvpAppDevelopmentStaticQuery'

const photoClientCaker =
  '../../../assets/images/avatars/clients/client-caker.png'
const photoClientImpact =
  '../../../assets/images/avatars/clients/client-impact.png'

const imageProps: CommonImageProps = {
  width: 50,
  height: 50,
}

const MvpAppDevTabContent = (): WorksTabsContent[] => {
  const query = useMvpAppDevelopmentStaticQuery()

  const portfolioImpact = getImage(fileToImageLikeData(query.portfolioImpact))
  const portfolioCaker = getImage(fileToImageLikeData(query.portfolioCaker))

  const tabContent = [
    {
      key: 'Fitness app',
      gradient: 'gradientImpact',
      image: portfolioImpact,
      header: 'Fitness PWA',
      title:
        'Impact is a fitness app that connects trainers and their clients via desktops and smartphones. The application allows for assigning and tracking personal training and nutrition programs.',
      resultsLeft: '400+ exercises',
      resultsRight: '2000+ workouts',
      link: 'fitness-progressive-web-application',
      review:
        '“The Codica team is professional and very helpful. They kept us informed on the project progress and promptly addressed all our questions and concerns. As a result, Codica delivered an app that our clients and trainers love. We are very happy with our collaboration and strongly recommend Codica as a reliable web development team.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientImpact}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Richard Gunter',
      clientPosition: 'Founder of Impact Personal Training',
    },
    {
      key: 'Bakery platform',
      gradient: 'gradientCaker',
      image: portfolioCaker,
      header: 'SaaS platform for CakerHQ',
      title:
        'CakerHQ is an Australian website for bakery businesses where cake makers sell flavorful desserts, and customers can book the confectionery easily and quickly.',
      resultsLeft: 'Optimized for mobile and desktop devices.',
      resultsRight: 'User-friendly and attractive solution.',
      link: 'saas-platform-for-bakery-business',
      review:
        '“Nowadays, CakerHQ is live and operational in Australia and is helping both cake business owners with their administration and their clients with having a simple way of searching and booking their custom bakes. We have plans to expand CakerHQ’s features and offerings in the future, and I look forward to bringing these to life with the Codica team.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientCaker}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Lisa',
      clientPosition: 'Founder of CakerHQ',
    },
  ]

  return tabContent
}

export default MvpAppDevTabContent
