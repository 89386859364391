import { ExpertiseSectionMock } from '~/types/mock'

const MvpAppDevPractices: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_78',
    title: 'Transparent communication',
    description:
      'We foster an environment of open and transparent communication where your ideas and feedback are valued, ensuring that you are a main participant of the process.',
  },
  {
    icon: 'spriteIcon_35',
    title: 'Performance optimization',
    description:
      'Our development process incorporates cutting-edge techniques for performance optimization, guaranteeing that your MVP operates seamlessly, even under heavy loads.',
  },
  {
    icon: 'spriteIcon_43',
    title: 'Focus on key features',
    description:
      'We collaborate closely with you to identify the critical components that will resonate with your target audience, giving your MVP a competitive edge.',
  },
  {
    icon: 'spriteIcon_11',
    title: 'User-centric approach',
    description:
      'Our specialists conduct in-depth user research and analysis to gain valuable insights into their needs, pain points, and preferences, ensuring higher engagement and user satisfaction.',
  },
  {
    icon: 'spriteIcon_1',
    title: 'Iterative development',
    description:
      'We embrace a flexible approach instead of aiming for perfection from the start, allowing continuous refinement based on user feedback and market dynamics.',
  },
  {
    icon: 'spriteIcon_68',
    title: 'Stability and reliability',
    description:
      'Regarding MVP in app development, our quality assurance testing services guarantee that your app functions flawlessly, minimizing downtime and fostering user trust.',
  },
]

export default MvpAppDevPractices
