// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "F_fS";
export var mvpAppDevCaseSection = "F_f0";
export var mvpAppDevChoiceSection = "F_fY";
export var mvpAppDevClientQuotes = "F_f1";
export var mvpAppDevCostSection = "F_fW";
export var mvpAppDevExpertiseSection = "F_fZ";
export var mvpAppDevIndustriesSection = "F_fQ";
export var mvpAppDevPracticesSection = "F_fX";
export var mvpAppDevPrimeSection = "F_fN";
export var mvpAppDevProcessSection = "F_fT";
export var mvpAppDevServicesSection = "F_fP";
export var mvpAppDevTechSection = "F_fV";
export var mvpAppDevTypesSection = "F_fR";