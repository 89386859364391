import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'

import LazyHydrate from '~/components/shared/LazyHydrate'
import {
  NavTechStack,
  TechStack,
} from '~/mock/tech-stack/TechStackCrossPlatform'

import ServicesTechStackWrapper from '../ServicesTechStackSection/components/ServicesTechStackWrapper'

import '../ServicesTechStackSection/ServicesTechStackSection.scss'

interface ServicesTechStackSectionCrossPlatformProps {
  isIosTech?: boolean
  isAndroidTech?: boolean
  isCrossPlatformMobileAppTech?: boolean
}

const formatKey = (title: string) => title.toLowerCase().replace(/\s/g, '-')
const firstNavTechStack = NavTechStack[0]

const ServicesTechStackSectionCrossPlatform = ({
  isCrossPlatformMobileAppTech,
  isAndroidTech,
  isIosTech,
}: ServicesTechStackSectionCrossPlatformProps) => (
  <LazyHydrate whenVisible>
    <div className="servicesTechStackSection">
      <Tab.Container
        id="techStackCrossPlatform"
        transition={false}
        defaultActiveKey={
          isAndroidTech ? 'Android' : formatKey(firstNavTechStack.title)
        }
      >
        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <Nav variant="tabs">
                {isAndroidTech && (
                  <Nav.Link eventKey="Android">Android</Nav.Link>
                )}
                {isIosTech && <Nav.Link eventKey="iOS">iOS</Nav.Link>}
                {NavTechStack.map(({ title }) => (
                  <Nav.Link eventKey={formatKey(title)} key={title}>
                    {title}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </div>
        </div>

        <Tab.Content>
          {isAndroidTech && (
            <Tab.Pane eventKey="Android">
              <ServicesTechStackWrapper
                title="Android"
                techStack={[
                  { icon: 'kotlin', name: 'Kotlin' },
                  { icon: 'java', name: 'Java' },
                ]}
              />
            </Tab.Pane>
          )}

          {isIosTech && (
            <Tab.Pane eventKey="iOS">
              <ServicesTechStackWrapper
                title="iOS"
                techStack={[{ icon: 'swift', name: 'Swift' }]}
              />
            </Tab.Pane>
          )}

          <Tab.Pane eventKey="cross-platform">
            {isCrossPlatformMobileAppTech ? (
              <ServicesTechStackWrapper
                title="Cross-platform"
                techStack={[
                  { icon: 'react', name: 'React' },
                  { icon: 'javascript', name: 'JavaScript' },
                  { icon: 'typescript', name: 'TypeScript' },
                  { icon: 'html', name: 'HTML5' },
                  { icon: 'flutter', name: 'Flutter' },
                  { icon: 'ionic', name: 'Ionic' },
                ]}
              />
            ) : (
              <ServicesTechStackWrapper
                title="Cross-platform"
                techStack={[
                  { icon: 'react', name: 'React' },
                  { icon: 'javascript', name: 'JavaScript' },
                  { icon: 'typescript', name: 'TypeScript' },
                  { icon: 'html', name: 'HTML5' },
                ]}
              />
            )}
          </Tab.Pane>

          {TechStack.map(({ title, techStack }) => (
            <Tab.Pane eventKey={formatKey(title)} key={title}>
              <ServicesTechStackWrapper title={title} techStack={techStack} />
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </div>
  </LazyHydrate>
)

export default ServicesTechStackSectionCrossPlatform
