import { HomeSpecification } from '~/types/mock'

const MvpAppDevIndustries: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description: 'Empowering virtual commerce',
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description: 'Seamless retail experiences',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description: 'Adventure at your fingertips',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description: 'Driving innovation forward',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description: 'Simplifying coverage management',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description: 'Smarter talent acquisition',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description: 'Effortless transactions became easier',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description: 'Captivating content delivery',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description: 'Revolutionizing patient care',
    link: '/case-studies/fitness-progressive-web-application/',
  },
]

export default MvpAppDevIndustries
