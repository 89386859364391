import { NavTechStackMock, TechStackMock } from '~/types/mock'

export const NavTechStack: NavTechStackMock[] = [
  {
    title: 'Cross-platform',
  },
  {
    title: 'Databases',
  },
  {
    title: 'DevOps',
  },
  {
    title: 'Other',
  },
]

export const TechStack: TechStackMock[] = [
  {
    title: 'Databases',
    techStack: [
      { icon: 'postgresql', name: 'PostgreSQL' },
      { icon: 'mysql', name: 'MySQL' },
      { icon: 'mongodb', name: 'Mongo DB' },
      { icon: 'redis', name: 'Redis' },
      { icon: 'elasticsearch', name: 'ElasticSearch' },
    ],
  },
  {
    title: 'DevOps',
    techStack: [
      { icon: 'aws', name: 'AWS' },
      { icon: 'ansible', name: 'Ansible' },
      { icon: 'docker', name: 'Docker' },
      { icon: 'gitlab', name: 'Gitlab CI/CD' },
      { icon: 'terraform', name: 'Terraform' },
      { icon: 'digitalocean', name: 'Digital Ocean' },
      { icon: 'heroku', name: 'Heroku' },
    ],
  },
  {
    title: 'Other',
    techStack: [
      { icon: 'firebase', name: 'Firebase' },
      { icon: 'material', name: 'Material UI' },
      { icon: 'bootstrap', name: 'Bootstrap' },
    ],
  },
]
