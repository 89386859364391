import { ExpertiseSectionMock } from '~/types/mock'

const MvpAppDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'MVP consulting',
    description:
      'At Codica, a strong foundation is vital for any successful experience. So, our team begins by crafting a well-defined marketing strategy tailored to your app’s unique value proposition.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'MVP application design',
    description:
      'Our designers combine aesthetics with user-centric interfaces, creating an intuitive and excellent user experience. Your app’s design will leave a pleasant mark on their minds.',
  },
  {
    icon: 'sprite3DIcon_3',
    title: 'MVP app development',
    description:
      'With unrivaled technical prowess and agile methodologies, we transform the prototype into a robust and scalable MVP. Every line of code is crafted with precision and passion.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'MVP application testing',
    description:
      'Rigorous testing, exhaustive evaluations, and a commitment to perfection allow our quality assurance (QA) engineers to eliminate imperfections and deliver a user-ready product.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Product support',
    description:
      'We offer comprehensive product support after the release. From regular updates to continuous improvements, we ensure your app remains at the forefront of innovation.',
  },
]

export default MvpAppDevServices
