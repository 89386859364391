import * as containerStylesSpriteIcons from '~/styles/modules/SpriteIcons.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './TableCostMvpAppDev.module.scss'

const TableCostMvpAppDev = () => (
  <div className={containerStyles.tableCostMvpDev}>
    <table className={containerStyles.tableCostMvpDev__tableWrapper}>
      <thead>
        <tr>
          <th scope="col">Mobile app type</th>
          <th scope="col">Cost</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div
              className={cx(
                containerStyles.tableCostMvpDev__content,
                containerStylesSpriteIcons.spriteIconsWrapper,
                containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_35,
              )}
            >
              <p>Expense trackers</p>
            </div>
          </td>
          <td>$19k - $27k</td>
        </tr>
        <tr>
          <td>
            <div
              className={cx(
                containerStyles.tableCostMvpDev__content,
                containerStylesSpriteIcons.spriteIconsWrapper,
                containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_47,
              )}
            >
              <p>Fitness/Health app</p>
            </div>
          </td>
          <td>$20k - $35k</td>
        </tr>
        <tr>
          <td>
            <div
              className={cx(
                containerStyles.tableCostMvpDev__content,
                containerStylesSpriteIcons.spriteIconsWrapper,
                containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_99,
              )}
            >
              <p>Travel app</p>
            </div>
          </td>
          <td>$21k - $33k</td>
        </tr>
        <tr>
          <td>
            <div
              className={cx(
                containerStyles.tableCostMvpDev__content,
                containerStylesSpriteIcons.spriteIconsWrapper,
                containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_94,
              )}
            >
              <p>Utility app</p>
            </div>
          </td>
          <td>$22k - $30k</td>
        </tr>
        <tr>
          <td>
            <div
              className={cx(
                containerStyles.tableCostMvpDev__content,
                containerStylesSpriteIcons.spriteIconsWrapper,
                containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_52,
              )}
            >
              <p>Crypto wallet</p>
            </div>
          </td>
          <td>$23k - $33k</td>
        </tr>
        <tr>
          <td>
            <div
              className={cx(
                containerStyles.tableCostMvpDev__content,
                containerStylesSpriteIcons.spriteIconsWrapper,
                containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_60,
              )}
            >
              <p>Social/Entertainment app</p>
            </div>
          </td>
          <td>$24k - $40k</td>
        </tr>
        <tr>
          <td>
            <div
              className={cx(
                containerStyles.tableCostMvpDev__content,
                containerStylesSpriteIcons.spriteIconsWrapper,
                containerStylesSpriteIcons.spriteIconsWrapper__spriteIcon_83,
              )}
            >
              <p>Ecommerce store/Marketplace app</p>
            </div>
          </td>
          <td>$26k - $40k</td>
        </tr>
      </tbody>
    </table>
  </div>
)

export default TableCostMvpAppDev
