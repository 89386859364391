import { ExpertiseSectionMock } from '~/types/mock'

const MvpAppDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_10',
    title: 'Cost-effectiveness',
    description:
      'Instead of wasting resources on unnecessary details, focus on the main idea of the product. It is much cheaper and helps to save money.',
  },
  {
    icon: 'spriteIcon_66',
    title: 'Faster time-to-market',
    description:
      'An MVP can be created quickly in about three months. Unlike full app development, which can take years, this speeds up the process significantly.',
  },
  {
    icon: 'spriteIcon_98',
    title: 'User feedback and validation',
    description:
      'By using MVP, you can get feedback and valuable ideas from your target audience. This helps to improve the product and adjust it to their needs.',
  },
  {
    icon: 'spriteIcon_15',
    title: 'Competitive advantage',
    description:
      'If the MVP shows potential, you can develop further on a proven foundation. Therefore, it is important to stay within the course and expand the functionality.',
  },
]

export default MvpAppDevBenefits
