import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { Item, Quote } from '~/types/data-array'
import { cx } from '~/utils'

import * as containerStyles from './ServicesSectionBeforeTestimonials.module.scss'

interface ServicesSectionBeforeTestimonialsProps {
  content: Quote[]
  imageOne?: IGatsbyImageData
  imageTwo?: IGatsbyImageData
  isList?: boolean
  list?: Item[]
  title?: string
  imageCenter?: IGatsbyImageData
  alignItems?: string
}

const ServicesSectionBeforeTestimonials = ({
  isList,
  list,
  title,
  content,
  imageOne,
  imageTwo,
  imageCenter,
  alignItems,
}: ServicesSectionBeforeTestimonialsProps) => (
  <div className="container container-md">
    <div className={cx('row', alignItems || 'align-items-center')}>
      <div className="col-12 col-md-6">
        {title && (
          <div className={containerStyles.servicesBeforeTestimonials__title}>
            {title}
          </div>
        )}

        {content.map(({ text }) => (
          <p
            key={text}
            className={containerStyles.servicesBeforeTestimonials__text}
          >
            {text}
          </p>
        ))}

        {isList && (
          <ul className={containerStyles.servicesBeforeTestimonials__list}>
            {list!.map(({ item }) => (
              <li
                key={String(item)}
                className={containerStyles.servicesBeforeTestimonials__listItem}
              >
                {item}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="col-12 col-md-6">
        {imageCenter ? (
          <div
            className={
              containerStyles.servicesBeforeTestimonials__imageWrapperCenter
            }
          >
            <GatsbyImage
              image={imageCenter}
              alt="The BML Feedback Loop is an approach used in Codica company when working with MVPs | Codica"
              title="Codica experts use the BML Feedback Loop when dealing with MVPs"
              loading="lazy"
            />
          </div>
        ) : (
          <div
            className={containerStyles.servicesBeforeTestimonials__imageWrapper}
          >
            {imageOne && (
              <GatsbyImage
                image={imageOne}
                alt="Codica team members working on a new project | Codica"
                title="Codica employees working on a new project"
                loading="lazy"
                className={
                  containerStyles.servicesBeforeTestimonials__imageWrapper_one
                }
              />
            )}
            {imageTwo && (
              <GatsbyImage
                image={imageTwo}
                alt="Services that suit your requirement | Codica"
                title="Services tailored for your needs"
                loading="lazy"
                className={
                  containerStyles.servicesBeforeTestimonials__imageWrapper_two
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  </div>
)

export default ServicesSectionBeforeTestimonials
